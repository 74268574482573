/* src/pages/About.css */

.about-container {
  padding: 40px 20px;
}

/* Section Titles */
.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
  margin-left: 20px;
}

.sub-section-title {
  font-size: 1.8rem;
  color: #4b0082; /* Purple for section titles */
  margin-bottom: 15px;
  margin-left: 20px;
}

/* About Content */
.about-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.about-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

/* Links */
.about-section a {
  color: #4b0082; /* Purple link color */
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.about-section a:hover {
  text-decoration: underline;
  color: #333; /* Darker hover effect */
}

/* Section Hover Effect */
.about-section:hover {
  transform: translateY(-5px); /* Hover animation similar to the cards */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}
