/* Ensure the body and html take the full height */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* The container that wraps all the page content */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make sure the container takes up the full viewport height */
}

/* The content area should grow to fill the space */
.content-wrap {
  flex: 1; /* This makes the content area take up available space */
  padding-top: 80px;
}

/* Make sure the footer stays at the bottom */
footer {
  background-color: #6d1b6f; /* Example color */
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
  position: relative;
}
