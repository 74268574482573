/* src/pages/Home.css */

.home-container {
  padding: 40px 20px; /* Padding around the home page */
}

/* Section Titles */
.section-title {
  font-size: 2rem; /* Size for the section title */
  font-weight: bold; /* Bold title */
  margin-bottom: 20px; /* Space below the title */
  color: #333; /* Darker color for title */
  text-align: left; /* Align title to the left */
  margin-left: 20px; /* Add margin to the left */
}

/* Who We Are Section */
.about-section {
  margin-top: 40px; /* Space above the section */
  padding: 20px; /* Padding inside the section */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.about-cards {
  display: flex; /* Flexbox layout for side-by-side cards */
  justify-content: space-between; /* Space between the cards */
  gap: 30px; /* Gap between cards */
  flex-wrap: wrap; /* Wrap on smaller screens */
  margin-top: 20px; /* Space between title and cards */
}

.about-card {
  flex: 1; /* Each card takes equal space */
  padding: 20px; /* Padding inside the card */
  background-color: #fff; /* White background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 45%; /* Maximum width for each card */
  min-width: 280px; /* Minimum width to ensure readability */
  transition: transform 0.3s; /* Smooth transform transition */
}

.about-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.card-title {
  font-size: 1.5rem; /* Size for card titles */
  font-weight: bold; /* Bold card title */
  color: #8b0000; /* Title color */
  margin-bottom: 10px; /* Space below title */
  display: flex; /* Flexbox to align arrow */
  align-items: center;
}

.arrow {
  margin-left: 8px; /* Space between text and arrow */
  font-size: 1.5rem; /* Arrow size */
}

.about-card p {
  font-size: 1rem; /* Paragraph font size */
  line-height: 1.6; /* Line height for readability */
  color: #555; /* Text color */
  margin-bottom: 15px; /* Space below paragraph */
}

/* Recent Updates Section */
.recent-updates-section {
  margin-top: 40px; /* Space above the section */
  padding: 20px; /* Padding inside the section */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.recent-updates-section h2 {
  text-align: left; /* Align the title to the left */
  margin-left: 20px; /* Add margin to the left */
}

.recent-updates-container {
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Column layout */
  gap: 15px; /* Gap between updates */
  padding: 10px; /* Padding inside */
}

/* Placeholder Card */
.placeholder-update {
  padding: 15px; /* Padding for the placeholder */
  border: 1px solid #ddd; /* Border for the placeholder */
  border-radius: 5px; /* Rounded corners */
  background-color: #fff; /* White background */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center; /* Center the text */
  font-style: italic; /* Italic text for placeholder */
}

.update-item h3 {
  font-size: 1.2rem; /* Size for update titles */
  font-weight: bold; /* Bold text */
  margin-bottom: 5px; /* Space below title */
}

.update-item p {
  color: #555; /* Text color */
  margin-bottom: 10px; /* Space below text */
  line-height: 1.4; /* Line height */
}

.update-item a {
  color: #ff6347; /* Link color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold text */
}

.update-item a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Home.css */
.card-link {
  color: inherit; /* Make the link color inherit from its parent */
  text-decoration: none; /* Remove underline from the link */
}

.card-link:hover {
  text-decoration: underline; /* Add underline on hover to indicate it's clickable */
}
