/* src/components/Footer.css */

/* Footer Container */
.footer-container {
  background-color: #6d1b6f; /* Same color as the navbar */
  padding: 20px;
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

/* Content inside Footer */
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap; /* Ensures responsiveness on smaller screens */
}

/* Footer Text */
.footer-text {
  flex: 1; /* Ensure the text takes space when search bar shrinks */
  text-align: left;
  font-size: 1rem;
  margin-bottom: 10px;
}

.footer-text p {
  margin: 0;
  font-size: 1rem;
}

/* Footer Search Bar */
.footer-search {
  display: flex;
  align-items: center;
  background-color: white; /* White background for search bar */
  padding: 5px;
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.footer-search-input {
  padding: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 200px;
  border-radius: 3px 0 0 3px; /* Rounded corners only on left side */
}

.footer-search-button {
  padding: 8px 10px;
  background-color: #ffd700; /* Gold color */
  border: none;
  color: #6d1b6f; /* Purple text */
  cursor: pointer;
  font-size: 1rem;
  border-radius: 0 3px 3px 0; /* Rounded corners on right side */
}

.footer-search-button:hover {
  background-color: #e6c300; /* Slightly darker gold on hover */
}

/* Responsiveness */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack the text and search bar vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .footer-search {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .footer-search-input {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .footer-search-button {
    width: 100%;
    border-radius: 5px;
  }

  .footer-text {
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }
}

/* Keep the footer always at the bottom */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}
