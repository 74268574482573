/* src/pages/Research.css */

.research-container {
  padding: 40px 20px;
}

/* Section Titles */
.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
  margin-left: 20px;
}

.sub-section-title {
  font-size: 1.8rem;
  color: #4b0082; /* Purple to match the theme */
  margin-bottom: 15px;
  margin-left: 20px;
}

/* Research and Key Activities Lists */
.research-list,
.key-activities-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 40px;
}

.research-list li,
.key-activities-list li {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 10px;
  transition: transform 0.2s, background-color 0.2s;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  color: #4b0082; /* Use purple for the text */
}

.research-list li:hover,
.key-activities-list li:hover {
  background-color: #e6e6fa; /* Light purple hover effect */
  transform: translateX(10px); /* Slide in on hover */
}

.list-icon {
  color: #4b0082; /* Purple icon color */
  margin-right: 10px;
  font-size: 1.5rem;
}

/* Prospective Students Section */
.prospective-students {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

.prospective-title {
  font-size: 1.8rem;
  color: #4b0082; /* Purple for the title */
  margin-bottom: 15px;
}

.prospective-students p {
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Highlighting the Contact Us link */
.contact-link {
  color: #fff;
  font-weight: bold;
  background-color: #4b0082; /* Highlight with a strong purple color */
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.contact-link:hover {
  text-decoration: underline;
  background-color: #333; /* Darker hover effect */
  color: #fff;
}
