/* Container for the People page */
.people-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Page Title */
.people-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

/* Section Titles */
.team-section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #6d1b6f; /* CUHK purple color */
  text-align: left;
  margin-left: 20px;
}

/* Grid layout for the team cards */
.team-grid {
  display: flex;
  flex-direction: column; /* Ensures one column layout, even on large screens */
  gap: 30px; /* Space between cards */
  margin-top: 20px;
}

/* Card styling for each team member */
.team-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.team-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow on hover */
}

/* Image container for consistent circular images */
.team-card-image-container {
  width: 150px;
  height: 150px;
  border-radius: 50%; /* Ensures the container is perfectly circular */
  overflow: hidden;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image fills the circular container */
  object-position: top;
}

/* Card body containing name, summary, and bio */
.team-card-body {
  padding: 10px 20px;
}

.team-card-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #6d1b6f;
}

.team-card-summary {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  text-align: justify;
  transition: max-height 0.3s ease;
}

/* "More details" and "Show less" links */
.more-details {
  color: #6d1b6f;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
}

.more-details:hover {
  color: #8b0000; /* Slightly darker hover effect */
}

/* Special styling for the director card */
.director-card .team-card {
  max-width: 100%; /* Ensure director card takes full width */
}

.director-card .team-card-image-container {
  width: 200px;
  height: 200px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .team-grid {
    flex-direction: column; /* Ensure single column layout on small screens */
  }

  .team-card {
    padding: 15px;
  }

  .team-card-name {
    font-size: 1.3rem;
  }

  .team-card-summary {
    font-size: 0.9rem;
  }

  .team-card-image-container {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .team-card-name {
    font-size: 1.2rem;
  }

  .team-card-summary {
    font-size: 0.85rem;
  }

  .team-card-image-container {
    width: 100px;
    height: 100px;
  }
}
