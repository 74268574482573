/* Main Container */
.contact-container {
  padding: 40px 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Form Styling */
.contact-form {
  margin-top: 20px;
  margin-bottom: 40px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1.1rem;
  color: white;
  background-color: #007acc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #005f99;
}

/* Direct Contact Email Section */
.direct-contact {
  margin-top: 30px;
  text-align: center;
}

.direct-contact h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.direct-contact p {
  font-size: 1.2rem;
  color: #555;
}

.direct-contact a {
  font-size: 1.2rem;
  color: #007acc;
  text-decoration: none;
}

.direct-contact a:hover {
  text-decoration: underline;
}

/* Google Map Section */
.map-container {
  margin-top: 40px;
}

.map-container h2 {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.map-container iframe {
  border: 0;
  width: 100%;
  height: 450px;
  max-width: 900px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Section Titles (if needed for other sections) */
.contact-section h2 {
  font-size: 1.8rem;
  color: #8b0000;
  margin-bottom: 10px;
}

/* Email Link Styling */
.contact-section a {
  font-size: 1.2rem;
  color: #007acc;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}

/* Location Text (optional, for any other details) */
.contact-section p {
  font-size: 1.2rem;
  color: #555;
}
