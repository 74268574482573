/* Hero Section Styles */
.hero {
  background: url("../assets/hero-bg.jpg") no-repeat center center/cover;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2;
  padding: 20px 20px;
  background: rgba(
    0,
    0,
    0,
    0.4
  ); /* Slightly darker overlay for text readability */
  border-radius: 10px;
}

.hero-content h1 {
  font-size: 3.5rem; /* Larger font for title */
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Stronger shadow for readability */
}

.lab-name {
  font-size: 2.8rem; /* Larger font size for lab name */
  margin: 0 auto;
  line-height: 1.4;
  color: #ffffff; /* CUHK Gold */
  font-weight: bold;
  /* text-transform: uppercase; Make the lab name stand out more */
  /* letter-spacing: 2px; Add letter spacing for emphasis */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Stronger shadow for better contrast */
  /* white-space: nowrap; Prevent text wrapping */
}
.lab-acronym {
  font-size: 2.8rem; /* Keeping the same size as the original lab-name */
  font-weight: bold; /* Ensuring "LEaPP" stands out */
  display: block; /* Make it appear on a separate line for better emphasis */
}

.lab-explanation {
  font-size: 1.4rem; /* Smaller font size for the explanation */
  display: block; /* Place the explanation on its own line */
  margin-top: 0.5rem; /* Adjust the spacing between "LEaPP" and the explanation */
  font-weight: normal; /* Less emphasis on the explanation */
  color: #ffffff; /* Same color to maintain consistency */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Slightly lighter shadow for explanation */
}
/* Responsive styling */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem; /* Adjusted font size for smaller screens */
  }

  .lab-name {
    font-size: 1.8rem; /* Adjusted font size for smaller screens */
    white-space: normal; /* Allow wrapping for smaller screens */
  }
}

/* Extra small screens styling */
@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem; /* Even smaller for extra small screens */
  }

  .lab-name {
    font-size: 1.5rem; /* Smaller font for extra small screens */
    white-space: normal; /* Allow wrapping on extra small screens */
  }
}
