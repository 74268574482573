/* Navbar Styles */
.navbar {
  background-color: #6d1b6f; /* Updated purple color */
  padding: 15px 20px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px; /* Define a fixed height for the navbar */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* School Logo */
.navbar-school-logo {
  margin-left: 10px;
}

.school-logo {
  width: 120px; /* Adjust the size */
  height: auto;
}

/* Navigation Links */
.navbar-right {
  display: flex;
  gap: 30px; /* Space between navigation links */
  justify-content: flex-end; /* Align navigation to the right */
  flex-grow: 1;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1.2rem; /* Adjusted font size */
}

.nav-link:hover {
  color: #ffd700; /* Gold hover effect */
}

.active {
  border-bottom: 2px solid #ffd700;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
  position: absolute;
  right: 120px; /* Adjust as needed to center properly */
  top: 25px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-right {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: #6d1b6f;
    width: 100%;
    text-align: center;
  }

  .nav-link {
    padding: 15px;
    font-size: 1.5rem;
  }

  .hamburger {
    display: block; /* Show hamburger on mobile */
  }

  .navbar-right.open {
    display: flex;
  }
}
